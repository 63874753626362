import React from "react";

const SocialNetwork = (props) => {
    return(
        <>
            <li>
                <a>
                    <div className="img-wrraper">
                        <img src={props.icon} alt="facebook" width="56px" />
                    </div>
                    <p className="mt-2 m-0">{props.name}</p>
                </a>
            </li>
        </>
    )
}

export default SocialNetwork;