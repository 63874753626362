import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import img1 from "../../images/16-9.png";
import img2 from "../../images/1-1.png";
import img4 from "../../images/9-16.png";
import { BsArrowLeft, BsPlusLg } from "react-icons/bs"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onFetchContent } from "../../actions/contentAction";
import VideoSetCard from "./VideoSetCard";

const VideoSet = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const createdCamp = useSelector(state => state.content.createdCamp)
console.log("createdCamp1111111",createdCamp)
   
    const [state, setState] = useState([])
    const [loader, setLoader] = useState(false)
    const fetchContentData = () => {
        console.log("11111111")
        let data = {
            "campaignId": createdCamp.campaignId
        }
        setLoader(true)
        dispatch(onFetchContent(data, setState, setLoader, history))
    }



    useEffect(() => {
        if (createdCamp.campaignId) {
           console.log("ka,mlesh")
            fetchContentData()
        }
    }, [createdCamp])
    console.log("state1111111",state)

    return (
        <>
            <TitleBar title="Video Sets" />
            <Navbar />
            <section className="siteWrap">
                <div className="contWrap">
                    <div className="container">
                        <div className="contWrap-title">
                            <Link to='/dashboard' style={{ color: "white", textTransform: "capitalize" }}>
                                <h2><BsArrowLeft /> {createdCamp.campaignName ? createdCamp.campaignName : ""}</h2>
                            </Link>
                        </div>
                        <div className="contWrap-list">
                            {state.length > 0 ?
                                null :
                                <div className="row text-center my-3">
                                    <div className="col-md-12">
                                        {loader
                                            ?
                                            <i className="fa fa-spinner fa-spin" style={{ color: "#3DAEC7", fontSize: "30px" }} />
                                            :
                                            <h5>You do not have any video set, To create one please click on the plus button.</h5>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {
                                    state.length > 0 ?
                                        state.map((curElem, index) => {
                                            console.log("aaaaaaa",curElem)
                                            let img = ""
                                            if (curElem.dimension === "1920x1080") {
                                                img = img1
                                            } else if (curElem.dimension === "1080x1920") {
                                                img = img4
                                            } else if (curElem.dimension === "1080x1080") {
                                                img = img2
                                            }
                                            return (
                                                <VideoSetCard
                                                    curElem={curElem}
                                                    img={img}
                                                    index={index}
                                                    fetchContentData={fetchContentData}
                                                />
                                            )
                                        }) : ""}
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <Link
                                        className="contWrap-list-single add"
                                        to={`/create-videoset`}
                                    >
                                        <div>
                                            <BsPlusLg className="largeIcon" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default VideoSet;