import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onSendMail } from '../../actions/outreachAction'

const SendMail = ({ data }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        to: "",
        subject: "",
        body: "",
        smtpId: -1
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onSendMail(state, setLoader))
    }

    return (
        <div className="tabepaneIn mt-5 outreactSendFrom">
            <div className="tabTitle mb-3">
                <h3>Send Email</h3>
            </div>

            <div className="tabepaneIn-main ">
                <div className="tabepaneIn-cont">
                    <div className="profileBlock-wrap profileBlock-wrap-last">

                        <form onSubmit={handleSubmit} className="outreachForm">

                            <div className="mb-3 d-flex align-items-center">
                                <div className="col-3 text-center">
                                    <label htmlFor="to" className="form-label ">To :</label>

                                </div>
                                <div className="col-9 form-group form-group-alt mt-0">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="to"
                                        name='to'
                                        placeholder="name@example.com"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                <div className="col-3 text-center">
                                    <label htmlFor="subject" className="form-label">Sub :</label>
                                </div>
                                <div className="col-9 form-group form-group-alt mt-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="subject"
                                        name='subject'
                                        placeholder="Enter Subject Here"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                <div className="col-3 text-center">
                                    <label htmlFor="body" className="form-label">Body :</label>
                                </div>
                                <div className="col-9 form-group form-group-alt mt-0">
                                    <textarea
                                        className="form-control pt-2"
                                        id="body"
                                        rows="3"
                                        name='body'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <hr />

                            <div className="mb-3 d-flex align-items-center">
                                <div className="col-3 text-center">
                                    <label htmlFor="smtpId" className="form-label">SMTP :</label>
                                </div>
                                <div className="col-9 form-group form-group-alt mt-0">
                                    <select
                                        className="form-control"
                                        name='smtpId'
                                        id='smtpId'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option>Select SMTP</option>
                                        {
                                            data.length > 0 ?
                                                data.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.id}>{curElem.username}</option>
                                                    )
                                                }) : ""
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="sendEmailbutton">
                                <button type='submit'>{loader ? <>Sending  <i className="fa fa-spinner fa-spin mr-2" /> </> : "Send"}</button>
                            </div>

                        </form>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default SendMail