import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import { loadUser } from "../../actions/authAction";
import { useDispatch } from "react-redux";


const Dashboard = () => {
    const dispatch = useDispatch()

    const [campLength, setCampLength] = useState(0)
    useEffect(() => {
        dispatch(loadUser())
    }, [])


    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />
            <section className="siteWrap">
                <DashboardHead campLength={campLength} />
                <DashboardContent setCampLength={setCampLength}/>
                <DashboardAbout />
            </section>
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;