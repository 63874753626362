import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";

const ResetPassword = ({ location }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })
    const [validationErrors, setValidationErrors] = useState([]);

    // const [msg, setMsg] = useState("")
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    });

    const onInputChange = (e) => {

        setPassword({ ...password, [e.target.name]: e.target.value });
    }
    const validatePassword = (password) => {
        const errors = [];
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;

        if (!pattern.test(password)) {
            errors.push("Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character, and be at least 8 characters long.");
        }
        return errors;
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        const errors = validatePassword(password.password);
        if (errors.length > 0) {
            setValidationErrors(errors);
            setPasswordMsg({ ...passwordMsg, msg: "", validation: false });
            return;
        }

        // Check if passwords match
        if (password.password === password.confirm_password) {
            setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true });
            setLoader(true);
            dispatch(resetPassword(password, setLoader, history));
        } else {
            setPasswordMsg({ ...passwordMsg, msg: "Passwords do not match!", validation: false });
        }
    }

    return (
        <>
            <TitleBar title="Reset Password" />
            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2>Welcome <span>Back!</span></h2>
                                <p>Create Incredible Videos On Scale Using AI & Just a Keyword! Dominate Your Niche With Short Engaging Videos!</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="************"
                                                required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input
                                                type="password"
                                                name="confirm_password"
                                                className="form-control"
                                                placeholder="************"
                                                required
                                                onChange={(e) => onInputChange(e)}
                                                autoComplete="off"
                                            />
                                        </div>
                                        {validationErrors.length > 0 && (
                                            <ul className="validation-errors">
                                                {validationErrors.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                        {passwordMsg.msg && (
                                            <div className={`text-${passwordMsg.validation ? 'success' : 'danger'}`}>{passwordMsg.msg}</div>
                                        )}
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <p className="forgotLink">Login ? <Link to="/login"> Click here</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    );

}


export default ResetPassword;