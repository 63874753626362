import React, { useState } from 'react'
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { onDeleteMedia, updateIntroOutro } from '../../../actions/contentAction';
import SweetAlert from 'react-bootstrap-sweetalert';
import HoverVideoPlayer from "react-hover-video-player";

const ModalTab = (props) => {
    const dispatch = useDispatch()
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const [intOut, setIntOut] = useState({
        id: false,
        type: '',
    })

    const selectIntro = (curElem) => {
        let data = {
            url: '',
            nameData: '',
            thumbData: ''
        }
        if (props.tabType === "videoReel") {
            data.url = curElem.prview_video_url
            data.nameData = curElem.project_name
            data.thumbData = curElem.preview_image_url
        }
        else {
            data.url = curElem.url
            data.nameData = curElem.name
            data.thumbData = curElem.poster
        }

        props.handleClose()
        props.setLoader(true)
        if (props.type === "intro") {
            dispatch(updateIntroOutro('UPDATE_INTRO', data, props.setLoader))
        }
        else {
            dispatch(updateIntroOutro('UPDATE_OUTRO', data, props.setLoader))
        }
    }

    const openDeletePopUp = (e, id, type) => {
        e.stopPropagation()
        setIntOut({
            ...intOut,
            id: id,
            type: type
        })
        setShowDelete(true);
    }

    const onConfirm = () => {
        handleDelete(intOut.id, intOut.type)
    }

    const handleDelete = (id) => {
        setButton("Deleting...")
        let formData = new FormData();
        formData.append('id', id);
        dispatch(onDeleteMedia(formData, setButton, setShowDelete, props.fetchIR))
    }

    return (
        <div
            className="col-md-4 col-6"
            key={props.index}
            onClick={() => selectIntro(props.curElem, props.tabType)}
        >
            <div className="credit-video-card" style={{ border: "none" }}>
                <div>
                    <>
                        <HoverVideoPlayer
                            className="cursor-pointer my-1"
                            videoSrc={props.url}
                            pausedOverlay={
                                <img src={props.thumbnail} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                            }
                            loadingOverlay={
                                <div ><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                            }
                        />
                        {props.tabType === "videoReel" ? <></> :
                            <FiTrash2
                                className='remove-media'
                                onClick={(e) => openDeletePopUp(e, props.curElem.id, props.type)}
                            />
                        }
                    </>
                </div>

            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </div>
    )
}

export default ModalTab