import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import PublishModal from "../PublishModal";
import { onDeleteMerge } from "../../../actions/contentAction";


const MergeCard = ({ item, fetchMerge }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [showModel, setShowModel] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [memberShip, setMemberShip] = useState([])

    const openDeletePopUp = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        let data = { id: deleteId, user_id: auth.user.id }
        dispatch(onDeleteMerge(data, fetchMerge, setShowDelete, setButtonText))
    }
    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>
            <div className="col-lg-3 col-md-6 mb-4 mt-5">
                <div className="video-card ">
                    <div className="video-img ">
                        {
                            item.output_video !== "" ?
                                <img src={item?.preview_image} alt="" className="img-fluid"
                                    style={{
                                        borderRadius: "10px"
                                    }}
                                /> :
                                <div className="video-card-wrapper-1" style={{ height: "192px" }}>
                                </div>

                        }
                        {
                            item.output_video === "" ?
                                <div className="render-overlay"><i>{item.status}</i></div>
                                : ''
                        }

                        <div className="ClipsReel-overlay">

                            <p className="mt-4 text-center text-white mb-3">{item.created}</p>

                            <ul className="list-inline m-0 px-2 d-flex justify-content-around mt-1">
                                {
                                    item.output_video !== "" ?
                                        <li className="text-center">
                                            <a href={`${item.output_video}`} style={{ color: "#fff" }} className="cursor-pointer">
                                                <span><i className="far fa-arrow-alt-circle-down"></i></span>
                                                Download
                                            </a>
                                        </li>
                                        : ''
                                }
                                {
                                    auth.user.is_client_account === "0" ?
                                        <>
                                            {
                                                item.output_video !== "" ?
                                                    <li className="text-center">
                                                        <a onClick={() => setShowModel(true)} style={{ color: "#fff" }} className="cursor-pointer">
                                                            <span><i className="fas fa-cloud-upload-alt"></i></span>
                                                            Publish
                                                        </a>
                                                    </li>
                                                    : ''
                                            }
                                            <li className="text-center">
                                                <a style={{ color: "#fff" }} className="cursor-pointer" onClick={() => openDeletePopUp(item.id)}>
                                                    <span><i className="far fa-trash-alt"></i></span>
                                                    Delete
                                                </a>
                                            </li>
                                        </>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>


                    <div className="video-title">
                        <div className="row">
                            <div className="col-md-12 col-12 d-flex align-items-center">
                                <div className="video-name text-center text-white">
                                    <p className="m-0">{item.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PublishModal
                    show={showModel}
                    setShow={setShowModel}
                    memberShip={memberShip}
                    curElem={item}
                />

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this campaign
                </SweetAlert>
            </div>


        </>
    )
}

export default MergeCard;