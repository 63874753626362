import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import Alert from "../../Alert";
import AudioFiles from "../AudioFiles";
import { onFetchSonority } from "../../../actions/contentAction";

const Sonority = ({ selectedSlideIndex, active }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const socialData = useSelector(state => state.social.sonority);

  const [loader, setLoader] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [musicData, setMusicData] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('');


  const connectSonority = () => {
    setShowDelete(true);
  }

  const onConfirm = () => {
    history.push('/integration')
  }

  const fetchSonorityMedia = () => {
    setLoader(true);
    dispatch(onFetchSonority(setMusicData, setLoader, socialData[0].token))
  }

  useEffect(() => {
    if (active.sonority) {
      if (socialData.length > 0) {
        fetchSonorityMedia();
      }

      return () => {
        setMusicData(false);
      }
    }
  }, [active.sonority])

  return (
    <>
      <Alert />
      <div className="" style={{ height: "auto" }}>
        {
          socialData.length > 0 ? <div className="alert alert-warning text-center mt-2" role="alert">
            This music will apply to the current slide
          </div>
            : <div className="alert alert-warning text-center" role="alert">
              You haven't connected your Sonority account yet. Click <a className="custom-anchor" onClick={() => connectSonority()}>Here</a> to connect and use those audios
            </div>
        }
        {
          loader ?
            <div className="col-md-12 text-center">
              <i className={`fa fa-spinner fa-spin`} style={{ color: '#CE88FF', fontSize: '25px' }} />
            </div>
            : ''
        }
        {
          loader ? '' : musicData !== false ? <h6 className="pb-2">Audio Tracks</h6> : ''
        }
        <div className="mediaList">
          <ul className="recording-wrapper">
            {
              musicData !== false ?
                musicData.merge.map((item, index) => {
                  return (
                    <AudioFiles
                      title="Uploaded VoiceOver"
                      selectedSlideIndex={selectedSlideIndex}
                      voiceOverName={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                      url={item.output_audio}
                      selectedAudio={selectedAudio}
                      setSelectedAudio={setSelectedAudio}
                      currentUrl={currentUrl}
                      setCurrentUrl={setCurrentUrl}
                      key={index}
                    />
                  )
                })
                : ''
            }
          </ul>
        </div>
        {
          loader ? '' : musicData !== false ? <h6 className="pt-4">Music Tracks</h6> : ''
        }
        <div className="mediaList pt-2">
          <ul className="recording-wrapper">
            {
              musicData !== false ?
                musicData.music.map((item, index) => {
                  return (
                    <AudioFiles
                      voiceOverName={item.title}
                      title="Uploaded VoiceOver"
                      selectedSlideIndex={selectedSlideIndex}
                      url={item.output_audio}
                      selectedAudio={selectedAudio}
                      setSelectedAudio={setSelectedAudio}
                      currentUrl={currentUrl}
                      setCurrentUrl={setCurrentUrl}
                      key={index}
                    />
                  )
                })
                : ''
            }
          </ul>
        </div>
        {
          loader ? '' : musicData !== false ? <h6 className="pt-4">Voice Tracks</h6> : ''
        }
        <div className="mediaList pt-2">
          <ul className="recording-wrapper">
            {
              musicData !== false ?
                musicData.tts.map((item, index) => {
                  return (

                    <AudioFiles
                      title="Uploaded VoiceOver"
                      selectedSlideIndex={selectedSlideIndex}
                      voiceOverName={item.title}
                      url={item.output_audio}
                      selectedAudio={selectedAudio}
                      setSelectedAudio={setSelectedAudio}
                      currentUrl={currentUrl}
                      setCurrentUrl={setCurrentUrl}
                      key={index}
                    />
                  )
                })
                : ''
            }
          </ul>
        </div>

      </div>

      <SweetAlert
        warning
        showCancel
        confirmBtnText="Connect"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Are you sure?"
        onConfirm={() => onConfirm()}
        onCancel={() => setShowDelete(false)}
        focusCancelBtn
        show={showDelete}
      >
        Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
      </SweetAlert>
    </>
  )
}

export default Sonority;