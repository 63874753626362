import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsArrowLeft } from "react-icons/bs"
import { onFetchVideos, removeVideo } from "../../actions/contentAction";
import { useDispatch, useSelector } from "react-redux";
import VideoListCard from "./VideoListCard";


const VideoList = () => {

    const dispatch = useDispatch()
    const createdCamp = useSelector(state => state.content.createdCamp)
    const [videoData, setVideoData] = useState(false)
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        id: -1,
        button: "Delete",
        show: false,
        name: ""
    })

    const handleDelete = (data) => {
        setState({
            ...state,
            id: data.id,
            show: true,
            name: data.name
        })
    }
    const onConfirm = () => {
        setState({
            ...state,
            button: "Deleting..."
        })
        dispatch(removeVideo(state, setState, fetchVideos))
    }
    const fetchVideos = () => {
        let data = {
            videoSetId: createdCamp.videoSetId,
        }
        setLoader(true)
        dispatch(onFetchVideos(data, setVideoData, setLoader))
    }
    useEffect(() => {
        if (createdCamp.videoSetId) {
            fetchVideos()
        }
    }, [createdCamp])

    return (
        <>
            <TitleBar title="Video List" />
            <Navbar />
            <section className="siteWrap">
                <div className="contWrap">
                    <div className="container">
                        <div className="contWrap-title">
                            <Link to={`/video-set`} style={{ color: "white", textTransform: "capitalize" }}>
                                <h2><BsArrowLeft />{createdCamp.campaignName}  <span>|</span> {videoData ? videoData.platform.name : ""} <span>|</span> {createdCamp.keyword} </h2>
                            </Link>
                        </div>
                        <div>
                            <div className="row">
                                {videoData ?
                                    videoData.videos.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <VideoListCard
                                                    handleDelete={handleDelete}
                                                    fetchVideos={fetchVideos}
                                                    curElem={curElem}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    : ""
                                }
                                {videoData ?
                                    videoData.videos.length > 0 ? ""
                                        :
                                        <div className="col-md-12 text-center mt-5">
                                            <h6>
                                                "No Videos Created Yet!"
                                            </h6>
                                        </div>

                                    :
                                    <div className="col-md-12 text-center mt-5">
                                        <h6>
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#3DAEC7", fontSize: "30px" }} /> :
                                                ""}
                                        </h6>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={state.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setState({
                    ...state,
                    show: false
                })}
                focusCancelBtn
                show={state.show}
            >
                <div style={{ color: "gray", fontWeight: "bold" }}>
                    Deleting the video "{state.name}" will delete all slides of it.
                </div>
            </SweetAlert>
            <Footer />
        </>
    )
}

export default VideoList;