import { commonAxios } from "../global/CommonAxios"
import { setAlert } from "./alert"

export const onFetch = (setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-smtp", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data.reverse())
            }
            else{
                setData([])
            }
            setLoader(false)
        })
        .catch((err) => {
            console.log(err)
            setLoader(false)

        })

}

export const onTestOutreach = (data, setTested, loader, setLoader) => (dispatch, getState) => {
    commonAxios("check-smtp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTested(true)
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                testLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                testLoader: false
            })
        })
}

export const onAddOutreach = (ep, data, loader, setLoader, handleClose, fetchOutreach) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchOutreach()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose()
            setLoader({
                ...loader,
                addLoader: false
            })
        }).catch((err) => {
            console.log(err)
            handleClose()
            setLoader({
                ...loader,
                addLoader: false
            })
        })
}

export const onDeleteOutreach = (state, setState, fetchOutreach) => (dispatch, getState) => {
    let data = {
        id: state.id
    }
    commonAxios("delete-smtp", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchOutreach()
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setState({
                ...state,
                id: -1,
                show: false,
                button: "Delete"
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setState({
                ...state,
                id: -1,
                show: false,
                button: "Delete"
            })
        })
}

export const onSendMail = (data, setLoader) => (dispatch, getState) => {
    commonAxios("send-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}
