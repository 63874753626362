import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { fetchVideoReel, getUploadData } from '../../../actions/contentAction'
import IntroOutro from './IntroOutro'

const Credits = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.content.campaign)
    const social = useSelector(state => state.social)
    const [introArr, setIntroArr] = useState([])
    const [outroArr, setOutroArr] = useState([])
    const [videoReel, setVideoReel] = useState({
        intro: [],
        outro: []
    })

    const fetchIntro = () => {
        const video = { type: "intro" }
        dispatch(getUploadData(video, setIntroArr))

    }

    const fetchOutro = () => {
        const video = { type: "outro" }
        dispatch(getUploadData(video, setOutroArr))

    }

    useEffect(() => {
        if (social.videoreel.length > 0) {
            const token = { "token": social.videoreel[0].token }
            dispatch(fetchVideoReel(token, videoReel, setVideoReel))
        }
    }, [social])


    useEffect(() => {
        fetchIntro()
        fetchOutro()
    }, [])

    return (
        <>
            <IntroOutro
                type="intro"
                data={campaign.intro}
                uploadedData={introArr}
                fetchIR={fetchIntro}
                videoReel={videoReel.intro}
            />
            <IntroOutro
                type="outro"
                data={campaign.outro}
                uploadedData={outroArr}
                fetchIR={fetchOutro}
                videoReel={videoReel.outro}
            />
        </>
    )
}

export default Credits