import React from "react";
import mac from "../../images/comp-1.png"
import mac2 from "../../images/comp-2.png"
import mac3 from "../../images/comp-3.png"
import { useSelector } from "react-redux";
import { appName } from "../../global/global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return(
        <>
            <section className="featureSec">
                    <div className="container-fluid">
                        <div className="featureSec-wrap">
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>Multiply Your Traffic With Marketing Videos</h6>
                                           <h2>Point & Click Software Creates Videos Automatically</h2>
                                           <p>Now you can create 100s of niche videos in seconds, with true push button simplicity and publish them on Facebook and YouTube natively for maximum traffic, views, viral exposure and sales!</p>
                                           <div className="textL">See how you can use {rebrand? rebrand.name : appName } to <span>create your first project</span></div>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>Easiest & Fastest Way to Create Video</h6>
                                           <h2>Put the FUN Back Into Video Creation</h2>
                                           <p>Hate editing templates or thinking of a video script or storyline? Worry not, because {rebrand? rebrand.name : appName } does all the work. Just enter a keyword and choose your content. No need to push your creative boundaries.</p>
                                           <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac2} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>No Restrictions & Easy to Master</h6>
                                           <h2>User-Friendly Interface. Use Anytime, Anywhere</h2>
                                           <p>Nothing to install, nothing to unpack, unzip and absolutely no technical knowledge required. Simply login to {rebrand? rebrand.name : appName } and start cranking out tons of videos with 100% control and full rights on the videos created.</p>
                                           <div className="textL">Find out how the <span>Team Members Access</span> feature works</div>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac3} /></div>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default DashboardAbout;