import React, { useState } from 'react'
import { useEffect } from 'react'
import { FaPlay, FaStop } from "react-icons/fa"
import { useDispatch } from 'react-redux'
import { onPlay, updateSelectedTiles } from '../../../actions/contentAction'

let voice = false, music = false, clearPlay = false, newIntervalId = false
const SlideActions = ({ campaign, selectedSlideIndex, totalslides, selectedSlide, setSecond, playStatus, setPlayStatus, timer, setTimer }) => {
    const dispatch = useDispatch()
    const [playLoader, setPlayLoader] = useState(false)
    const handleForwardSlide = () => {
        if (totalslides - 2 >= selectedSlideIndex) {
            dispatch(updateSelectedTiles(selectedSlideIndex + 1))
        }
    }

    const handleBackwardSlide = () => {
        if (selectedSlideIndex > 0) {
            dispatch(updateSelectedTiles(selectedSlideIndex - 1))
        }
    }

    const handlePlay = () => {
        const bgMusic = campaign.backgroundAudio
        const voiceData = selectedSlide.audio
        if (voiceData.enable) {
            setPlayLoader(true)
            voice = new Audio(voiceData.src)
            voice.volume = voiceData.volume / 100
            voice.play().then(() => {
                setPlayLoader(false)
                setPlayStatus(true)
                if (bgMusic.enable) {
                    music = new Audio(bgMusic.source)
                    music.volume = bgMusic.volume / 100
                    // let lengthAfter = 0, i = 0

                    // for (i; i < selectedSlideIndex; i++) {
                    //     lengthAfter += +campaign.slides[i].duration
                    // }
                    // if (campaign.backgroundAudio.duration < lengthAfter) {
                    //     lengthAfter = 0
                    //     for (i; i < selectedSlideIndex; i++) {
                    //         lengthAfter += +campaign.slides[i].duration
                    //     }
                    // }
                    // music.src = music.src + `#t=${lengthAfter}`
                    music.play()
                }
                let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                newIntervalId = setInterval(() => {
                    setSecond(prevSec => prevSec + 1)
                }, 1000);

                clearPlay = setTimeout(() => {
                    handlePause()
                }, durToPlay * 1000)
                dispatch(onPlay(selectedSlideIndex))
            })
        }
        else {
            if (bgMusic.enable) {
                music = new Audio(bgMusic.source)
                music.volume = bgMusic.volume / 100
                // let lengthAfter = 0
                // for (let i = 0; i < selectedSlideIndex; i++) {
                //     lengthAfter += +campaign.slides[i].duration
                // }
                // if (campaign.backgroundAudio.duration < lengthAfter) {
                //     lengthAfter = 0
                // }
                // music.src = music.src + `#t=${lengthAfter}`
                music.play().then(() => {
                    setPlayStatus(true)
                    setPlayLoader(true)
                    let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                    newIntervalId = setInterval(() => {
                        setSecond(prevSec => prevSec + 1)
                    }, 1000);

                    clearPlay = setTimeout(() => {
                        handlePause()
                    }, durToPlay * 1000)
                    dispatch(onPlay(selectedSlideIndex))
                })
            }
            else {
                setPlayStatus(true)
                let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                newIntervalId = setInterval(() => {
                    setSecond(prevSec => prevSec + 1)
                }, 1000);

                clearPlay = setTimeout(() => {
                    handlePause()
                }, durToPlay * 1000)
                dispatch(onPlay(selectedSlideIndex))
            }

        }

    }

    const handlePause = () => {
        setPlayLoader(false)
        setSecond(0)
        setTimer({
            ...timer,
            h: 0,
            m: 0
        })
        if (voice) {
            voice.pause()
            voice = false
        }
        if (music) {
            music.pause()
            music = false
        }
        clearTimeout(clearPlay)
        clearInterval(newIntervalId)
        setPlayStatus(false)
    }

    useEffect(() => {
        if (playStatus) {
            handlePause()
        }
    }, [selectedSlideIndex])

    useEffect(() => {
        return () => {
            if (voice) {
                voice.pause()
                voice = false
            }
            if (music) {
                music.pause()
                music = false
            }
            clearTimeout(clearPlay)
            clearInterval(newIntervalId)
        }
    }, [])


    return (
        <>
            <li onClick={handleBackwardSlide} title="Backward Slide">
                <i class="fa fa-step-backward" aria-hidden="true"></i>
            </li>
            {playStatus ?
                <li onClick={handlePause} title="Stop">
                    <FaStop />
                </li>
                :
                playLoader ? <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "18px" }} /> :
                    <li onClick={handlePlay} title="Play">
                        <FaPlay />
                    </li>
            }

            <li onClick={handleForwardSlide} title="Forward Slide">
                <i class="fa fa-step-forward" aria-hidden="true"></i>
            </li>

        </>
    )
}

export default SlideActions