import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import AudioFiles from '../AudioFiles';
import { onAudioFetch } from '../../../actions/contentAction';

const Music = ({ slideData, activeMusic }) => {
    const dispatch = useDispatch()
    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState(false);
    const [audioData, setAudioData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [error, setError] = useState(false);

    const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === "1")
    const [selectedAudio, setSelectedAudio] = useState(false)

    const fetchAudio = (page = 1) => {
        setLoader(true);
        let data = {
            search_type: 'music',
            page_number: page,
            keyword: ''
        }
        dispatch(onAudioFetch(data, audioData, setAudioData, setPageCount, setError, setLoader, page))
    }

    useEffect(() => {
        if (activeMusic) {
            fetchAudio();
            return () => {
                setAudioData([]);
            }
        }
    }, [activeMusic])

    return (
        <div className="innertab">
            <div className="innertab-scroll" id="scrollableAudio123">
                <div className="mediaList">
                    <InfiniteScroll
                        dataLength={audioData.length} //This is important field to render the next data
                        next={() => fetchAudio(pageCount + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableAudio123"
                    >
                        <ul>
                            {audioData.length > 0 ?
                                audioData.map((audio, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <AudioFiles
                                                title={audio.title}
                                                selectedSlideIndex={selectedSlideIndex}
                                                url={audio.url}
                                                className="audioList-single-music"
                                                selectedAudio={selectedAudio}
                                                setSelectedAudio={setSelectedAudio}
                                                currentUrl={currentUrl}
                                                setCurrentUrl={setCurrentUrl}
                                                key={index}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : ''}


                        </ul>
                    </InfiniteScroll>

                    {
                        loader ?
                            <div className="col-md-12">
                                <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2"  style={{color : "#3DAEC7"}}/></h4>
                            </div>
                            : ''
                    }
                    {
                        error ?
                            <div className="col-md-12">
                                <h6 className="text-center">{error}</h6>
                            </div>
                            : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Music