import React from 'react'

const settingMenu = () => {
    return (
        <>
            <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                <a className="nav-link" id="v-pills-wlOutreach-tab" data-toggle="pill"
                    href="#v-pills-wlOutreach" role="tab" aria-controls="v-pills-wlOutreach"
                    aria-selected="false">Outreach
                </a>
            </div>
        </>
    )
}
export default settingMenu;