import React from 'react'
import modalCloseIcon from '../../images/modal-close.png';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';

const OcpCopyModal = ({ ocp, setOcp }) => {

    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        let copyText = document.getElementById("copyUrltoCopy");
        setCopied(true)
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    useEffect(() => {
        if (!ocp.show) {
            setCopied(false)
        }
    }, [ocp])

    return (
        <Modal className="VideoModal small" show={ocp.show} centered>
            <Modal.Body>
                <div onClick={() => setOcp({
                    ...ocp,
                    show: false,
                    data: ""
                })} className="vidClose"><img src={modalCloseIcon} alt="" /></div>

                <div className="formSec">
                    <div className="inpField">
                        <label>Your Client Preview URL </label>
                        <input
                            type="text"
                            className="inpField-inp text-light my-2"
                            placeholder="Preview URL"
                            value={ocp.data}
                            id="copyUrltoCopy"
                            readOnly={true}
                        />
                    </div>
                    <button
                        type="button"
                        className="btn-block inpBtn mt-3 create-modal-btn"
                        onClick={() => handleCopy()}
                    >
                        {copied ? "Copied" : "Copy"}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OcpCopyModal