import React from 'react'
import { useSelector } from 'react-redux';
import { appName } from '../../global/global';
import helpDeskImg from '../../images/help-desk.png';

function CustomerSupport() {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <section className="helpDesk">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="helpDesk-left">
                        <img src={helpDeskImg} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="helpDesk-right">
                        <h6>customer support</h6>
                        <h2>Can't find your answer?</h2>
                        <p>We're here to help! From general communication to specifc {rebrand? rebrand.name : appName } platform questions, please reach out so we can assist.</p>
                        <a target="_blank" className="demoLink" href="https://support.vineasx.com/" rel="noreferrer">Contact Our Helpdesk</a>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerSupport
