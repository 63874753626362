const initialState = {
    articles: [],
    videos: []
}

export default function (state = initialState, action){
    switch (action.type) {

        case 'ADD_ARTICLES':
            return{
                ...state,
                articles: action.payload,
            }

        case 'ADD_VIDEOS':
            return {
                ...state,
                videos: action.payload
            }

        default:
            return state
    }
}