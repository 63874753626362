import React from 'react'

const Plateform = ({ data }) => {
    return (
        data.length > 0 ?
            <>
                <optgroup label={data[0].group_name}>
                    {data.map((curElem) => {
                          let obj = { dimension: curElem.dimension, id: curElem.id }
                        return (
                            <option value={JSON.stringify(obj)}>{curElem.name}</option>
                        )
                    })
                    }
                </optgroup>
            </>
            : ""


    )
}

export default Plateform