import { setAlert } from "./alert";
import { commonAxios } from "../global/CommonAxios";

export const fetchRebrandData = () => (dispatch, getState) => {
    commonAxios("fetch-wl-rebranding", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch({ type: 'ADD_REBRAND', payload: res.data });
            } else {
                dispatch({ type: 'REMOVE_ADD_REBRAND' });

            }
        }).catch((err) => {
            console.log(err)
        })
}


export const addRebrandData = (data, setLoader) => (dispatch, getState) => {
    commonAxios("wl-rebranding", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch(fetchRebrandData());
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const onDeleteRebrand = (data, setShowDelete, setButton) => (dispatch, getState) => {
    commonAxios("delete-wl", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'REMOVE_ADD_REBRAND' });
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete')

        }).catch((err) => {
            setShowDelete(false);
            setButton('Delete')
            console.log(err)
        })
}