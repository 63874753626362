import React from 'react'
import lessonImg from '../../images/lesson-img.png';
import { useSelector } from 'react-redux';
import { appName } from '../../global/global';

function Webinar() {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="lessonLink">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="lessonLink-left"><img src={lessonImg} alt=""/></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lessonLink-right">
                                <h6><span> {rebrand ? rebrand.name : appName}</span> LESSON #1</h6>
                                <h2>Join Us for our LIVE Webinar.</h2>
                                <p>Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                                <a className="demoLink" href="https://abhineos.com/training" target="_blanck"><i class="fa fa-play" aria-hidden="true"></i> Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Webinar
