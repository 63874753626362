import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';

const ResolutionModal = (props) => {
    const [state, setState] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        props.setResolution(state)
        props.setShow(false)

    }
    return (
        <Modal className="VideoModal small reel_merge_model" show={props.show} centered>
            <Modal.Body>
                <div onClick={() => props.setShow(false)} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <div className="col-12 ">
                            <div className="Select_heading text-center">
                                <h3>Select Resolution</h3>
                            </div>
                            <div className="set_resolution text-center mt-3" >
                                <div className='d-flex justify-content-center' >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="flexCheckDefault"
                                            name='resolution'
                                            onChange={() => setState("1920x1080")}
                                        />
                                        <span className="" for="flexCheckDefault">
                                            16:9
                                        </span>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="flexCheckChecked"
                                            name='resolution'
                                            onChange={() => setState("1080x1920")}
                                        />
                                        <span className="" for="flexCheckChecked">
                                            9:16
                                        </span>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="flexCheckChecked"
                                            name='resolution'
                                            onChange={() => setState("1080x1080")}
                                        />
                                        <span className="" for="flexCheckChecked">
                                            1:1
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="set_btn_wrapper text-center">
                        <button type="submit" className="btn set_resolution_btn  mt-3 ">Submit</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ResolutionModal