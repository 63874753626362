import React, { useState } from 'react'
import { useEffect } from 'react'
import ContentEditable from 'react-contenteditable'

const CtaView = ({ data }) => {
  

    return (
        <div className={`logo-${data.position}`} style={{ zIndex: 2 }}>
            <ContentEditable
                html={data.data}
                disabled={true}
                onChange={null}
            />
        </div>
    )
}

export default CtaView