import React from 'react';
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline, IoIosInformationCircleOutline } from "react-icons/io"
import { IoWarningOutline } from "react-icons/io5"
import { useSelector } from 'react-redux';

const Alert = () => {
    const alertData = useSelector(state => state.alert)
    let obj = {
        color: alertData.type === 'success' ? "#90EE90" :
            alertData.type === 'danger' ? "#DC143C" :
                alertData.type === 'warning' ? "#FFD700" :
                    alertData.type === 'info' ? "#1E90FF" : ''
    }

    const icon = alertData.type === 'success' ? <IoMdCheckmarkCircleOutline color={obj.color} /> :
        alertData.type === 'danger' ? <IoMdCloseCircleOutline color={obj.color} /> :
            alertData.type === 'warning' ? <IoWarningOutline color={obj.color} /> :
                alertData.type === 'info' ? <IoIosInformationCircleOutline color={obj.color} /> : ''
    return (
        alertData.message !== "" && alertData.message !== undefined ?
            <div className='alert-container'  >
                <div
                    className={`alert-class animate__bounceInRight animate__animated ${alertData.type === 'success' ? "linear-border" : ""}`}
                    style={alertData.type === 'success' ? {} : { border: `2px solid ${obj.color}` }}>
                    <div className='alert-icon-style animate__heartBeat animate__animated animate__delay-1s pb-1'>
                        {icon}
                    </div>
                    <div className='alert-text-style'>
                        <span>{alertData.message}</span>
                    </div>
                </div>
            </div > : ''
    );
}

export default Alert;
