import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onUpdateLogoInfo } from '../../../actions/contentAction'

const Logo = (props) => {
    const dispatch = useDispatch()
    const logoRef = useRef(null)

    useEffect(() => {
        if (props.logo.src !== "") {
            setTimeout(() => {
                dispatch(onUpdateLogoInfo(logoRef.current.clientHeight, logoRef.current.clientWidth))
            }, 2000)
        }else{
            dispatch(onUpdateLogoInfo(0, 0))
        }
    }, [props.logo.src])

    return (
        props.logo.src !== "" ?
            <div className={`logo-${props.logo.pos} logo-style`}>
                <img
                    ref={logoRef}
                    src={props.logo.src}
                    alt="logo"
                />
            </div>
            : ''
    )
}

export default Logo