import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FontStyle from './FontStyle';
import ColorPicker from './ColorPicker';
import { addInnerStyle, applyToAllSlide, onFetchFont, removeInnerStyle, textLayerAdd, textLayerUpdate } from '../../../actions/contentAction';
import ContentEditable from 'react-contenteditable';
import InlineColorPicker from './InlineColorPicker';
import { BsQuestionCircle } from "react-icons/bs"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import sanitizeHtml from 'sanitize-html';


const Text = ({ slideData }) => {

  const dispatch = useDispatch();
  const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")

  const [slideAll, setSlideAll] = useState(false);

  const [selectedLayer, setSelectedLayer] = useState(false)
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(-1)

  const [layerText, setLayerText] = useState("")
  const [fonts, setFonts] = useState([])
  const [dataHigh, setDataHigh] = useState({
    text: "",
    color: "",
    background: ""
  })
  const [restriction, setRestriction] = useState(true);

  const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
    , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
    , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
    , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

  const [styleState, setStyleState] = useState({
    color: "#000000",
    fontFamily: "",
    background: false,
    fontSize: "36",
    textAlign: "center",
    fontWeight: false,
    fontStyle: false,
    textTransform: "lowercase",
    textDecoration: "none",
    lineHeight: 1,
    opacity: 1,
  })

  const handleChecked = (e) => {
    if (selectedLayer.type === "text") {
      setSlideAll(false);
      if (e.target.checked) {
        dispatch(addInnerStyle(selectedSlideIndex, selectedLayerIndex, styleState.background))
      } else {
        dispatch(removeInnerStyle(selectedSlideIndex, selectedLayerIndex))
      }
    }
  }


  function handleBlur(event) {
    setRestriction(true)
  }

  function handleClick(event) {
    let text = event.currentTarget.innerText;
    setRestriction(false)
    if (selectedLayer.type === "text") {
      const clean = sanitizeHtml(text, {
        allowedTags: []
      });
      setLayerText(clean)
      dispatch(textLayerUpdate(clean, selectedSlideIndex, selectedLayerIndex))
    }
  }

  useEffect(() => {
    if (slideData) {
      let val = slideData[selectedSlideIndex].layers.find(({ isSelected }) => isSelected === true)
      let valIndex = slideData[selectedSlideIndex].layers.findIndex(({ isSelected }) => isSelected === true)
      setSelectedLayer(val)
      setSelectedLayerIndex(valIndex)
      if (val && restriction) {
        if (val.textToHighlight) {
          if (val.textToHighlight.length > 0) {
            let htmlTextdata = val.src
            val.textToHighlight.forEach((curElem) => {
              htmlTextdata = htmlTextdata.replaceAll(curElem.text, `<span style="color:${curElem.color}; background : ${curElem.background}">${curElem.text}</span>`);
            })
            setLayerText(htmlTextdata)

          }
          if (val.textToHighlight.length === 0) {
            setLayerText(val.src)
          }
        }
      }
    }
  }, [slideData, selectedSlideIndex, restriction])

  useEffect(() => {
    if (selectedLayer) {
      if (selectedLayer.type === "shape" || selectedLayer.type === "text") {
        setStyleState((selectedLayer.style))
      }

    }
  }, [selectedLayer])

  const applyChanges = () => {
    if (slideAll === false) {
      setSlideAll(true);
      dispatch(applyToAllSlide(styleState, selectedLayer.innerCss));
    }
  }

  const onLayerTextChange = (text) => {
    if (selectedLayer.type === "text") {
      const clean = sanitizeHtml(text, {
        allowedTags: []
      });
      setLayerText(clean)
      dispatch(textLayerUpdate(clean, selectedSlideIndex, selectedLayerIndex))
    }
  }

  const addTextLayer = () => {
    dispatch(textLayerAdd(selectedSlideIndex))
  }

  const fetchFonts = () => {
    dispatch(onFetchFont(setFonts, fontNotSet))
  }
  const handleHighlight = () => {
    const selObj = window.getSelection().toString();
    if (selObj !== "") {
      setDataHigh({
        ...dataHigh,
        text: selObj
      })
    }
  }



  // useEffect(() => {
  //   if (selectedLayer && inputValue) {
  //     if (selectedLayer.textToHighlight) {
  //       if (selectedLayer.textToHighlight.length > 0) {
  //         let htmlTextdata = selectedLayer.src
  //         selectedLayer.textToHighlight.forEach((curElem) => {
  //           // let searchMask = curElem.text;
  //           // let regEx = new RegExp(searchMask, "ig");
  //           // let replaceMask = `<span style="color:${curElem.color}; background : ${curElem.background}">${curElem.text.toLowerCase()}</span>`;
  //           // htmlTextdata = htmlTextdata.replaceAll(regEx, replaceMask);
  //           htmlTextdata = htmlTextdata.replaceAll(curElem.text, `<span style="color:${curElem.color}; background : ${curElem.background}">${curElem.text}</span>`);
  //         })
  //         setLayerText(htmlTextdata)
  //       }
  //       if (selectedLayer.textToHighlight.length === 0) {
  //         setLayerText(selectedLayer.src)
  //       }
  //     }
  //   }
  // }, [selectedLayer, inputValue])

  useEffect(() => {
    fetchFonts()
  }, [])

  return (
    <div className="tabInner">
      <div className="textarea-block-wrap mb-4">
        <div className="textarea-block-head d-flex justify-content-between align-items-center">
          <h2>Text</h2>

          <button
            className='textarea-btn alt'
            onClick={addTextLayer}
          >Add New Text Layer</button>
        </div>
        {selectedLayer ?
          selectedLayer.type === "text" ?
            <div className="textarea-wrapper pt-3 ">
              <div className="mb-3">
                <ContentEditable
                  className="form-control"
                  html={layerText}
                  disabled={false}
                  onBlur={handleBlur}
                  onClick={(e) => handleClick(e)}
                  onMouseUp={() => handleHighlight()}
                  onChange={(e) => onLayerTextChange(e.target.value)}
                  style={{ height: "100px", overflowX: "hidden", overflowY: "auto" }}
                />
              </div>
            </div> : "" : ""}
      </div>
      {
        selectedLayer ?
          selectedLayer.type === "text" || selectedLayer.type === "shape" ?
            <div className="txt-format">
              {selectedLayer.type === "text" ?
                <>

                  <h2>Font Style</h2>
                  <FontStyle
                    styleState={styleState}
                    selectedLayer={selectedLayer}
                    selectedSlide={selectedSlide}
                    slideIndex={selectedSlideIndex}
                    setSlideAll={setSlideAll}
                    fonts={fonts}
                  />
                  <div className="fontStyle-block-wrap">
                    <h2>Color</h2>
                    <ColorPicker
                      colorCode={styleState.color}
                      setStyleState={setStyleState}
                      selectedSlide={selectedSlide}
                      slideIndex={selectedSlideIndex}
                      type="textColor"
                      setSlideAll={setSlideAll}
                    />

                  </div>
                </>
                : ""}

              {selectedLayer.type === "text" || selectedLayer.type === "shape" ?
                <div className="fontStyle-block-wrap">
                  <h2>Background</h2>
                  <ColorPicker
                    colorCode={selectedLayer.innerCss ? selectedLayer.innerCss.background : styleState.background}
                    setStyleState={setStyleState}
                    selectedSlide={selectedSlide}
                    slideIndex={selectedSlideIndex}
                    type="bgColor"
                    setSlideAll={setSlideAll}
                  />
                </div> : ""}
              {selectedLayer.type === "text" ?
                <>
                  <div className="fontStyle-block-wrap">
                    <div className='d-flex align-items-center'>
                      <label className='cursor-pointer' style={{ display: "contents" }}>
                        <input
                          type="checkbox"
                          id='addTtsAll'
                          checked={selectedLayer.innerCss && selectedLayer.type === "text"}
                          onChange={(e) => handleChecked(e)}
                          style={{ width: '28px', height: "19px" }}
                        />
                        <span className='align-self-center ms-2' >Add Space Between Text</span>
                      </label>
                    </div>
                  </div>
                  <div className="fontStyle-block-wrap mb-3">
                    <h2>Highlight Color
                      <span className='mx-1' >

                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id="tooltip-top" >
                              Please select words first to highlight it.
                            </Tooltip>
                          }
                        >
                          <p style={{ display: "inline-block", position: "relative", top: "-1px" }}> <BsQuestionCircle color='#b571e9' className='mb-0 ms-1' size={17} cursor="help" /></p>


                        </OverlayTrigger>


                      </span>
                    </h2>
                    <InlineColorPicker
                      dataHigh={dataHigh}
                      slideIndex={selectedSlideIndex}
                      layerIndex={selectedLayerIndex}
                      selectedLayer={selectedLayer}
                      type="color"
                    />
                  </div>

                  <div className="fontStyle-block-wrap mb-3">
                    <h2>Highlight Background Color
                      <span className='mx-1' >

                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id="tooltip-top" >
                              Please select words first to highlight it.
                            </Tooltip>
                          }
                        >
                          <p style={{ display: "inline-block", position: "relative", top: "-1px" }}> <BsQuestionCircle color='#b571e9' className='mb-0 ms-1' size={17} cursor="help" /></p>
                        </OverlayTrigger>
                      </span>

                    </h2>
                    <InlineColorPicker
                      dataHigh={dataHigh}
                      slideIndex={selectedSlideIndex}
                      layerIndex={selectedLayerIndex}
                      selectedLayer={selectedLayer}
                      type="bgcolor"

                    />
                  </div>
                  <div className="fontStyle-block-wrap">
                    <div className="itemDisp">
                      <div className="itemDisp-left">
                        Apply to all slides
                      </div>
                      <div className="itemDisp-right">
                        <div className="switch-single">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={slideAll}
                              disabled={slideAll}
                              onChange={() => applyChanges()}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                </> : ""}
            </div>
            : "" : ""
      }
    </div >
  );
};

export default Text;
