import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import iconrender from "../../images/icon-render.svg";
import iconStory from "../../images/icon-story.svg";
import iconText from "../../images/icon-text.svg";
import iconMedia from "../../images/icon-media.svg";
import iconAudio from "../../images/icon-audio.svg";
import iconSettings from "../../images/icon-settings.svg";
import iconCredits from "../../images/icon-credits.svg";
import iconTransition from "../../images/icon-transition.svg";
import animationIcon from "../../images/animation-icon.svg";
import { Tab, Nav } from "react-bootstrap";
import TitleBar from "../TitleBar";
import Audio from "./audio/Audio"
import Preview from "./preview/Preview";
import VideoTiles from "./videoTiles/videoTiles";
import Scripts from "./scripts/Scripts";
import Text from "./text/Text";
import Media from "./media/Media";
import BackgroundMedia from "./backgroundMedia/BackgroundMedia";
import Setting from "./setting/Setting";
import Credits from "./credits/Credits";
import Transition from "./transition/Transition";
import Animation from "./animation/Animation";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import VoiceOver from "./voiceOver/VoiceOver";
import { onFetchVideo, onRender } from "../../actions/contentAction";
import { useHistory } from "react-router-dom";


const Editor = ({ location }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { id } = queryString.parse(location.search)
  const content = useSelector(state => state.content)
  const [loader, setLoader] = useState(true)
  const [renderLoader, setRenderLoader] = useState(false)
  const [preLoader, setPreLoader] = useState(false)
  const [memberShip, setMemberShip] = useState([])
  const [active, setActive] = useState({
    music: false,
    transition: false
  })
  const [deactiveButton, setDeactiveButton] = useState(false)


  const handleActive = (type) => {
    if (type === "music" && active.music === false) {
      setActive({
        ...active,
        music: true
      })
    }
    else if (type === "transition" && active.transition === false) {
      setActive({
        ...active,
        transition: true
      })
    }
  }


  const handleRender = () => {
    const data = {
      videoId: id,
      type: "render"
    }
    setRenderLoader(true)
    dispatch(onRender(data, setRenderLoader, history))
  }

  const fetchVideos = () => {
    dispatch(onFetchVideo(id, history, setLoader))
  }


  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])

  useEffect(() => {
    fetchVideos()
  }, [])


  return (
    loader ?
      <div className="loader-sec">
        <div className="loader">
        </div>
      </div> :
      <>
        <TitleBar title="Editor" />
        <Navbar />
        <section className="siteWrap">
          <div className="editorWrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey="story">

              <div className="editor-left">

                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="story">
                      <Scripts
                        slideData={content.campaign.slides}
                        memberShip={memberShip}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="text">
                      <Text
                        slideData={content.campaign.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="bgMedia">
                      <BackgroundMedia
                        slideData={content.campaign.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="media">
                      <Media
                        slideData={content.campaign.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="voiceOver">
                      <VoiceOver
                        slideData={content.campaign.slides}
                        setDeactiveButton={setDeactiveButton}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="audio">
                      <Audio
                        activeMusic={active.music}
                        slideData={content.campaign.slides}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="settings">
                      <Setting
                        setPreLoader={setPreLoader}
                        setLoader={setLoader}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="credits">
                      <Credits
                      />
                    </Tab.Pane>
                    {memberShip.includes("unlimited") || memberShip.includes("unlimited-lite") || memberShip.includes("bundle") ?
                      <>
                        <Tab.Pane eventKey="transition">
                          <Transition
                            slideData={content.campaign.slides}
                            transActive={active.transition}
                            memberShip={memberShip}
                          />
                        </Tab.Pane>

                        <Tab.Pane eventKey="animation">
                          <Animation
                            slideData={content.campaign.slides}
                            memberShip={memberShip}
                          />
                        </Tab.Pane>
                      </>
                      :
                      <>

                      </>
                    }


                  </Tab.Content>
                </div>
              </div>

              <div className="editor-right">
                <div className="campaignTop">
                  <div className="campaignBar">

                    <button
                      className="demoLink alt cursor-poninter"
                      onClick={handleRender}
                      style={{ background: deactiveButton ? "gray" : "" }}
                      disabled={deactiveButton}
                    >
                      {renderLoader ?
                        <>
                          Rendering
                          <i className="fa fa-spinner fa-spin mx-1" />

                        </> :
                        <>
                          Render
                          <img src={iconrender} alt='render-icon' className="mx-1" />

                        </>
                      }
                    </button>
                  </div>
                  <div className="campaignNav">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="story">
                          <div>
                            <span><img alt="" src={iconStory} /></span>
                            <span>Script</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="text">
                          <div>
                            <span><img alt="" src={iconText} /></span>
                            <span>Text</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="bgMedia">
                          <div>
                            <span><img alt="" src={iconMedia} /></span>
                            <span>Background</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="media">
                          <div>
                            <span><img alt="" src={iconMedia} /></span>
                            <span>Media</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="voiceOver">
                          <div>
                            <span><img alt="" src={iconAudio} /></span>
                            <span>Voiceover</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item onClick={() => handleActive("music")}>
                        <Nav.Link eventKey="audio">
                          <div>
                            <span><img alt="" src={iconAudio} /></span>
                            <span>Music</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      {memberShip.includes("unlimited") || memberShip.includes("unlimited-lite") || memberShip.includes("bundle") ?
                        <>
                          <Nav.Item onClick={() => handleActive("transition")}>
                            <Nav.Link eventKey="transition">
                              <div>
                                <span><img alt="" src={iconTransition} /></span>
                                <span>Transition</span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="animation">
                              <div>
                                <span><img alt="" src={animationIcon} /></span>
                                <span>Animation</span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </> :
                        <>
                        </>
                      }
                      <Nav.Item>
                        <Nav.Link eventKey="settings">
                          <div>
                            <span><img alt="" src={iconSettings} /></span>
                            <span>Settings</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="credits">
                          <div>
                            <span><img alt="" src={iconCredits} /></span>
                            <span>Credits</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>


                {/* ------------ Preview --------- */}
                <Preview
                  dimension={content.campaign.dimension}
                  campaign={content.campaign}
                  logo={content.campaign.logo}
                  watermark={content.campaign.watermark}
                  preLoader={preLoader}
                />
              </div>
            </Tab.Container>
          </div>
        </section>

        <div className="editorReel">
          <VideoTiles
            slideData={content.campaign.slides}
          />
        </div>
      </>
  )
}

export default Editor;