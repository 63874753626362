import React, { useMemo, useState } from "react";
import sample from "../../../images/sample.png";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import swal from "sweetalert";
import Template from "./Template";
import TemplateVarient from "./TemplateVarient";
import VolumeControl from "./VolumeControl";
import { VscMute, VscUnmute } from "react-icons/vsc"
import { addSlideDuration, muteVideoAudio, onUploadLW, removeLogoWatermark, unMuteVideoAudio, updateLogoWatermark, updateStatus } from "../../../actions/contentAction";
import EditorSummerNote from "./EditorSummerNote";

const Setting = ({ setPreLoader, setLoader }) => {
  const dispatch = useDispatch();
  const campaign = useSelector(state => state.content.campaign)

  const logoData = campaign.logo
  const waterMarkData = campaign.watermark
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

  const [loadLogo, setLoadLogo] = useState(false);
  const [loadWatermark, setLoadWatermark] = useState(false);




  const [name, setName] = useState({
    logoName: 'Upload Your Logo',
    waterMarkName: 'Upload Your Watermark'
  });

  const logo = useMemo(() => {
    return logoData
  }, [logoData])

  const waterMark = useMemo(() => {
    return waterMarkData
  }, [waterMarkData])



  const addDuration = (e) => {
    let num = e.target.value
    if (num > 300) {
      dispatch(setAlert("You can't set slide duration more than 300 sec.", "warning"))
      dispatch(addSlideDuration(300))
    } else {
      dispatch(addSlideDuration(num))
    }
  }


  const onLoadFile = (file, type) => {
    if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
      if (file.size < 5000000) {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('upload_type', type);

        type === "logo" ?
          setLoadLogo(true)
          :
          setLoadWatermark(true)
        dispatch(onUploadLW(formData, selectImage, name, setName, setLoadLogo, setLoadWatermark, type))

      } else {
        swal("Oops!", "Max allowed size 5MB");
      }
    } else {
      swal("Oops!", "You have Selected Invalid File Type");
    }
  }

  const logoWaterPos = (position, type) => {
    let fileType = 'UPDATE_LOGO_POSITION';
    if (type === 'watermark') {
      fileType = 'UPDATE_WATERMARK_POSITION';
    }
    dispatch(updateLogoWatermark(fileType, position));
  }

  const selectImage = (url, type) => {
    let uploadType = 'ADD_LOGO';
    if (type === "watermark") {
      uploadType = 'ADD_WATERMARK';
    }
    dispatch(updateLogoWatermark(uploadType, url));
  }

  const handleLogoWaterMark = (e, type) => {
    if (type === 'logo') {
      dispatch(updateStatus(e.target.checked, 'logo'))
      if (e.target.checked === false) {
        setName({
          ...name,
          logoName: 'Upload Your Logo'
        })
        dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
      }
    }

    if (type === 'watermark') {
      dispatch(updateStatus(e.target.checked, 'watermark'))
      if (e.target.checked === false) {
        dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
        setName({
          ...name,
          waterMarkName: 'Upload Your Watermark'
        })
      }
    }
  }

  const handleMuteVideoLayer = (data) => {
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    if (data.mute === false) {
      dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
    }
  }

  function hasAudio(video) {
    return video.mozHasAudio ||
      Boolean(video.webkitAudioDecodedByteCount) ||
      Boolean(video.audioTracks && video.audioTracks.length);
  }


  const handleUnMuteVideoLayer = (selectedLayer) => {
    let video = document.querySelector('#checkAudioData');
    video.play()
    video.muted = true
    setTimeout(() => {
      let data = hasAudio(video)
      if (data) {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayer.mute) {
          dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
      } else {
        dispatch(setAlert("This video does not have an audio", "danger"))
      }
      video.pause()
    }, 1000)
  }


  return (
    <>
      <div className="tabInner">
        <div className="media-block">
          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title  mb-4">
                <h2>Add Logo</h2>
                <div className="switch-single">
                  <label className="switch" htmlFor="logo">
                    <input
                      type="checkbox"
                      checked={logo.enable}
                      id="logo"
                      onChange={(e) => handleLogoWaterMark(e, "logo")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {logo.enable ?
                <>
                  <div className="fileUpload"
                    style={logo.src ? { color: '#fff' } : {}}
                  >
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    {name.logoName.length > 20 ? `${name.logoName.slice(0, 20)}...` : name.logoName}
                    <input
                      type="file"
                      className='cursor-pointer'
                      onChange={(e) => onLoadFile(e.target.files[0], "logo")}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left text-center">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {
                            loadLogo ?
                              <i className="fa fa-spinner fa-spin loader-center" />
                              : <img
                                src={logo.src ?
                                  logo.src : sample}
                                alt=""
                                className="uploadImg"
                              />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="itemDisp-right">

                      <select
                        onChange={(e) => logoWaterPos(e.target.value, 'logo')}
                        className='cursor-pointer'>
                        <option value={'top-left'}>Top Left</option>
                        <option value={'top-center'}>Top Center</option>
                        <option value={'top-right'}>Top Right</option>
                        <option value={'bottom-left'}>Bottom Left</option>
                        <option value={'bottom-center'}>Bottom Center</option>
                        <option value={'bottom-right'}>Bottom Right</option>
                      </select>
                    </div>
                  </div>
                </>
                : ''}
            </div>
          </div>


          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title  mb-4">
                <h2>Add Watermark</h2>
                <div className="switch-single">
                  <label className="switch" htmlFor="watermark">
                    <input
                      type="checkbox"
                      id="watermark"
                      checked={waterMark.enable}
                      onChange={(e) => handleLogoWaterMark(e, "watermark")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              {waterMark.enable ?
                <>
                  <div className="fileUpload"
                    style={waterMark.src !== "" ? { color: '#fff' } : {}}
                  >
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    {name.waterMarkName.length > 23 ? `${name.waterMarkName.slice(0, 23)}...` : name.waterMarkName}
                    <input
                      type="file"
                      id="inputGroupFile01"
                      className='cursor-pointer'
                      onChange={(e) => onLoadFile(e.target.files[0], 'watermark')}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left text-center">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {
                            loadWatermark ?
                              <i className="fa fa-spinner fa-spin loader-center" />
                              : <img
                                src={waterMark.src ? waterMark.src : sample}
                                alt=""
                                className="uploadImg"
                              />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="itemDisp-right">
                      <select
                        onChange={(e) => logoWaterPos(e.target.value, 'watermark')}
                        className='cursor-pointer'>
                        <option value={'top-left'}>Top Left</option>
                        <option value={'top-center'}>Top Center</option>
                        <option value={'top-right'}>Top Right</option>
                        <option value={'bottom-left'}>Bottom Left</option>
                        <option value={'bottom-center'}>Bottom Center</option>
                        <option value={'bottom-right'}>Bottom Right</option>
                      </select>
                    </div>
                  </div>
                </>
                : ''}
            </div>
          </div>


          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title mb-4">
                <h2>Duration (Sec)</h2>
              </div>
              <input
                className="inPut"
                type="number"
                name="duration"
                min={1}
                value={selectedSlide.duration}
                onChange={(e) => addDuration(e)}
              />
            </div>
          </div>
          {selectedLayer ?
            selectedLayer.type === "video" ?
              <div className="add-block-single">
                <div className="add-block-main">
                  <div className="add-block-title mb-4">
                    <h2>Mute/Unmute Video</h2>
                    {selectedLayer.mute ?
                      <VscMute
                        size={25}
                        onClick={() => handleUnMuteVideoLayer(selectedLayer)}
                        className="cursor-pointer"
                      /> :
                      <VscUnmute
                        size={25}
                        onClick={() => handleMuteVideoLayer(selectedLayer)}
                        className="cursor-pointer"
                      />
                    }

                  </div>
                </div>
              </div>
              : "" : ""}


          <VolumeControl />


          <EditorSummerNote
            promo={campaign.promo}
          />

          <Template
            setLoader={setLoader}
          />

          <TemplateVarient
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
            setPreLoader={setPreLoader}
          />
        </div>

      </div>
      {selectedLayer ?
        selectedLayer.type === "video" ?
          <video
            className="no-showvideotag"
            id="checkAudioData"
            controls
            src={selectedLayer.src ? selectedLayer.src : ""}
          /> : ""

        : ""
      }
    </>
  );
};


export default Setting;
