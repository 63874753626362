import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import img1 from "../../images/campaign-img.png";
import { BsArrowLeft } from "react-icons/bs"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onPlatform, onSubmitVideoSet } from "../../actions/contentAction";
import SummerNote from "./SummerNote";
import Plateform from "./Plateform";


const CreateVideoSet = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const createdCamp = useSelector(state => state.content.createdCamp)

    const [loader, setLoader] = useState(false)
    const [platformData, setPlatformData] = useState(false)

    const [state, setState] = useState({
        campaignsId: createdCamp.campaignId,
        name: "",
        keyword: "",
        videoLimit: 1,
        platformId: "",
        dimension: "",
        promo: {
            enable: false,
            data: "",
            position : "top-left"
        }
    })

    const handleProceed = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onSubmitVideoSet(state, setLoader, history))
    }
    const fetchPlateform = () => {
        dispatch(onPlatform(setPlatformData))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "platform") {
            let obj = JSON.parse(value)
            setState({
                ...state,
                dimension: obj.dimension,
                platformId: obj.id
            })
        } else {
            setState({
                ...state,
                [name]: value
            })
        }

    }

    const handlePromo = (e) => {
        if (e.target.name === "position") {
            setState({
                ...state,
                promo: {
                    ...state.promo,
                    position: e.target.value
                }
            })
        }
    }


    const handleChangePromo = (e) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                enable: e.target.checked
            }
        })
    }
    useEffect(() => {
        fetchPlateform()
    }, [])

    return (
        <>
            <TitleBar title="Create Video Set" />
            <Navbar />
            <section className="siteWrap">
                <div className="contWrap">
                    <div className="container">
                        <div className="contWrap-title">
                            <Link to={`/video-set`} style={{ color: "white", textTransform: "capitalize" }}>
                                <h2><BsArrowLeft /> {createdCamp.campaignName ? createdCamp.campaignName : ""}</h2>
                            </Link>
                        </div>
                        <div className="campaign-name">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="campaign-name-left">
                                        <div><img src={img1} alt="" /></div>
                                    </div>
                                </div>
                                <div className="col-sm-6">

                                    <form className="campaign-name-mid" onSubmit={handleProceed}>
                                        <h5 className="pt-0 pb-2">Create New Video Set</h5>
                                        <div className="campaign-name-inp">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <label>Video Set</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input
                                                        className="campaign-name-inp-box"
                                                        type="text"
                                                        name="name"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="campaign-name-inp">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <label>Keyword</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input
                                                        className="campaign-name-inp-box"
                                                        type="text"
                                                        name="keyword"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="campaign-name-inp">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <label># of video</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input
                                                        className="campaign-name-inp-box"
                                                        type="number"
                                                        name="videoLimit"
                                                        min={1}
                                                        max={10}
                                                        onWheel={(event) => event.currentTarget.blur()}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="campaign-name-inp">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <label>Platform</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <select
                                                        name="platform"
                                                        className="campaign-name-inp-box"
                                                        onChange={handleChange}
                                                        required
                                                        style={{ textTransform: "capitalize" }}
                                                    >
                                                        <option value="">Select Platform</option>
                                                        {platformData ?
                                                            <>
                                                                <Plateform
                                                                    data={platformData.Facebook}
                                                                />
                                                                <Plateform
                                                                    data={platformData.Instagram}
                                                                />
                                                                <Plateform
                                                                    data={platformData.LinkedIn}
                                                                />
                                                                <Plateform
                                                                    data={platformData.Pinterest}
                                                                />
                                                                <Plateform
                                                                    data={platformData.Snapchat}
                                                                />
                                                                <Plateform
                                                                    data={platformData.TikTok}
                                                                />
                                                                <Plateform
                                                                    data={platformData.Twitter}
                                                                />
                                                                <Plateform
                                                                    data={platformData.YouTube}
                                                                />
                                                            </>

                                                            : ""}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="campaign-name-inp">
                                            <div className="row align-items-center mt-2">
                                                <div className="col-md-10">
                                                    <label>CTA</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={state.promo.enable}
                                                            onChange={handleChangePromo}
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>

                                                </div>
                                            </div>


                                            {state.promo.enable ?
                                                <>
                                                    <div className="mt-3 ck-class">
                                                        <SummerNote
                                                            setState={setState}
                                                            state={state}
                                                        />
                                                    </div>

                                                    <div className="row align-items-center mt-3">
                                                        <div className="col-sm-12">
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-3">
                                                                    <label>Position</label>
                                                                </div>
                                                                <div className="col-sm-9">
                                                                    <select
                                                                        name="position"
                                                                        className="campaign-name-inp-box"
                                                                        style={{ textTransform: "capitalize" }}
                                                                        onChange={handlePromo}
                                                                    >
                                                                        <option value="">Select Position</option>
                                                                        <option value={"top-left"}>top left</option>
                                                                        <option value={"top-center"}>top center</option>
                                                                        <option value={"top-right"}>top right</option>
                                                                        <option value={"bottom-left"}>bottom left</option>
                                                                        <option value={"bottom-center"}>bottom center</option>
                                                                        <option value={"bottom-right"}>bottom right</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </> : ""}
                                        </div>

                                        <div className="campaign-name-inp">
                                            <div className="row align-items-center">
                                                <div className="col-sm-12">
                                                    <div>
                                                        <button className="demoLink" type="submit">Submit {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ""}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-sm-3">
                                    <div className="campaign-name-left">
                                        <div><img src={img1} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default CreateVideoSet;