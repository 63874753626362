import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { onGoogleLangFetch, onTranslate, revertTranslate } from '../../../actions/contentAction'

const TranslateText = ({ selectedSlide, selectedSlideIndex, setDataToRevert, memberShip }) => {
    const dispatch = useDispatch()
    const [transLang, setTransLang] = useState([])
    const [translate, setTranslate] = useState({
        active: false,
        loader: false,
        tCode: "",
        transText: ""
    })

    const handleLang = (val) => {
        setTranslate({
            ...translate,
            tCode: val
        })
    }
    const handleActive = (val) => {
        setTranslate({
            ...translate,
            active: val
        })
    }
    const revertText = () => {
        dispatch(revertTranslate(selectedSlideIndex))
    }

    const translateText = () => {
        if (translate.tCode !== "" && selectedSlide.audio.meta.text !== "") {
            const data = {
                lang_code: translate.tCode,
                text: selectedSlide.audio.meta.text
            }
            setDataToRevert(selectedSlide.audio.meta.text)
            setTranslate({
                ...translate,
                loader: true
            })
            dispatch(onTranslate(data, setTranslate, translate, selectedSlideIndex))


        } else {
            if (translate.tCode === "") {
                dispatch(setAlert("Please select the language in which you are translating", "danger"))
            }
            else if (selectedSlide.audio.meta.text === "") {
                dispatch(setAlert("Please add text that you wanna translate", "danger"))
            }
        }
    }

    useEffect(() => {
        let text = selectedSlide.audio.meta.translateText
        setTranslate({
            ...translate,
            transText: text
        })
    }, [selectedSlide.audio.meta.translateText])

    useEffect(() => {
        if (memberShip.length > 0) {
            dispatch(onGoogleLangFetch(setTransLang, memberShip))
        }
    }, [memberShip])

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title mb-4">
                    <h2>Translate Text</h2>
                    <div className="switch-single">
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                checked={translate.active}
                                onChange={(e) => handleActive(e.target.checked)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {translate.active ?
                    <div className="mediaList">
                        <div className="voice-block-top">
                            <div className="row">
                                <div className="col-6">
                                    <select
                                        value={translate.tCode}
                                        onChange={(e) => handleLang(e.target.value)}
                                        className="cursor-pointer"
                                        required
                                    >
                                        <option value={""} selected>Select Language</option>
                                        {transLang.length > 0 ?
                                            transLang.map((curElem) => {
                                                return (
                                                    <option
                                                        value={curElem.code}
                                                        key={curElem.id}
                                                    >
                                                        {curElem.name}
                                                    </option>
                                                )
                                            })
                                            : ''}
                                    </select>
                                </div>
                                <div className="col-6 text-right">
                                    {translate.transText !== "" ?

                                        <button
                                            onClick={() => revertText()}
                                            style={{ fontSize: "14px" }}
                                            className="translate-btn ">

                                            Revert Text {translate.loader ? <i className="fa fa-spinner fa-spin" /> : ''}
                                        </button> :

                                        <button onClick={translateText} className="translate-btn ">
                                            <span style={{ fontSize: "14px" }}>Translate</span> {translate.loader ? <i className="fa fa-spinner fa-spin" /> : ''}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </div>
        </div>
    )
}

export default TranslateText