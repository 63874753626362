import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";

const AddAccount = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team',
        email: '',
        name: '',
        password: '',
    });
    const [passwordError, setPasswordError] = useState('');

    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        if (e.target.name === "password") {
            setPasswordError('');
        }
    }

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTeamData(res.data.data.team_members);
                setClientData(res.data.data.clients);
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}create-client`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam();
                setUserDetails({
                    ...userDetails,
                    type: 'team_member',
                    email: '',
                    name: '',
                    password: ''
                });
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            console.error(error);
        });
    }

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) return "Password must be at least 8 characters long.";
        if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
        if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
        if (!hasNumbers) return "Password must contain at least one number.";
        if (!hasSpecialChars) return "Password must contain at least one special character.";

        return "";
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        const passwordValidationError = validatePassword(userDetails.password);
        if (passwordValidationError) {
            setLoader(false);
            setPasswordError(passwordValidationError);
            return;
        }

        addClientTeam();
    }

    useEffect(() => {
        fetchClientTeam();
    }, []);

    return (
        <>
            <div className="profileBlock-wrap">
                <h2 className="infoTitle">Create Account</h2>
                <div className="profileBlock mt-0">
                    <form onSubmit={onFormSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Type</label>
                                    <select className="form-control" name="type" onChange={onInputChange}>
                                        <option style={{ backgroundColor: "#000" }} value="team">Team Member</option>
                                        <option style={{ backgroundColor: "#000" }} value="client">Client Account</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Name"
                                        required
                                        name="name"
                                        value={userDetails.name}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={userDetails.email}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="********"
                                        required
                                        name="password"
                                        value={userDetails.password}
                                        onChange={onInputChange}
                                    />
                                    {passwordError && <small className="text-danger">{passwordError}</small>}
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                            </button>
                        </div>
                    </form>
                </div>

                <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} type="Client" />
                <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} type="Team Member" />
            </div>
        </>
    );
}

export default AddAccount;
